<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <button (click)="closeModal.next(existsFault)" type="button" class="close">
                <i class="material-icons">clear</i>
            </button>
            <h4 class="modal-title">Toote praagiks märkimine</h4>
            <h6><b>{{product.name}}</b></h6>
        </div>
        <div *ngIf="!formValues" class="modal-body">
            Loading...
        </div>
        <form *ngIf="formValues" #form="ngForm">
            <div class="modal-body">
                <label>Vali praagi põhjustanud töölõik *</label>
                <br>
                <select name="reason" [(ngModel)]="formValues.reason" required>
                    <option *ngFor="let reason of productFaultReasons" [value]="reason.reason">
                        {{reason.getText()}}
                    </option>
                </select>
                <br>
                <div class="form-group label-floating is-empty">
                    <label for="fault-description">
                        Kirjelda lühidalt, miks tekkis praagiks märkimise vajadus
                    </label>
                    <textarea
                        name="description"
                        id="fault-description"
                        [(ngModel)]="formValues.description"
                        class="form-control"
                        maxlength="200"
                    ></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeModal.next(existsFault)" type="button" class="btn btn-secondary">Tühista</button>
                <button *ngIf="existsFault" (click)="deleteFault()" type="button" class="btn btn-danger">
                    Pole praak
                </button>
                <button (click)="save()" type="button" class="btn btn-success">Salvesta</button>
            </div>
        </form>
    </div>
</div>
