<nav #navbar class="navbar navbar-transparent navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-minimize">
            <button id="minimizeSidebar" class="btn btn-round btn-white btn-fill btn-just-icon">
                <i class="material-icons visible-on-sidebar-regular">more_vert</i>
                <i class="material-icons visible-on-sidebar-mini">view_list</i>
            </button>
        </div>
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="{{getPath()}}"> {{getTitle()}} </a>
        </div>
        <div class="collapse navbar-collapse">
            <div *ngIf="isMobileMenu()">
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <a (click)="signout()" href="#" class="dropdown-toggle" data-toggle="dropdown">
                            <i class="material-icons">exit_to_app</i>
                            Logi välja
                            <!--<p class="hidden-lg hidden-md">Profile</p>-->
                        </a>
                    </li>
                    <li class="separator hidden-lg hidden-md"></li>
                </ul>
                <ng-container *ngIf="searchBarVisible">
                    <form class="navbar-form navbar-right" role="search" (submit)="search()">
                        <div class="form-group form-search is-empty">
                            <input [(ngModel)]="searchQuery" name="searchQuery" type="text" class="form-control" placeholder="Otsi">
                            <span class="material-input"></span>
                        </div>
                        <button type="submit" class="btn btn-white btn-round btn-just-icon">
                            <i class="material-icons">search</i>
                            <div class="ripple-container"></div>
                        </button>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</nav>
