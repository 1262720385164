<div class="wrapper wrapper-full-page">
    <nav class="navbar navbar-primary navbar-transparent navbar-fixed-top">
        <div class="container">
            <div class="navbar-header">
                <a [routerLink]="['/dashboard']" class="navbar-brand">Coop</a>
            </div>
        </div>
    </nav>
    <div class="full-page login-page" filter-color="black">
        <!--  you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                        <form (ngSubmit)="login()" method="#" action="#">
                            <div class="card card-login card-hidden">
                                <div class="card-header text-center" data-background-color="green">
                                    <h4 class="card-title">Login</h4>
                                </div>
                                <div class="card-content">
                                    <div class="input-group">
                                        <span class="input-group-addon">
                                            <i class="material-icons">email</i>
                                        </span>
                                        <div class="form-group label-floating">
                                            <label class="control-label">E-posti aadress</label>
                                            <input [(ngModel)]="form.email" name="email" type="email" class="form-control">
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <span class="input-group-addon">
                                            <i class="material-icons">lock_outline</i>
                                        </span>
                                        <div class="form-group label-floating">
                                            <label class="control-label">Parool</label>
                                            <input [(ngModel)]="form.password" name="password" type="password" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="footer text-center">
                                    <button type="submit" class="btn btn-success btn-simple btn-wd btn-lg">Logi sisse</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="full-page-background" style="background-image: url(../../../assets/img/login.jpeg) "></div>
    </div>
</div>
