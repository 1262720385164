<div class="logo" style="padding:5px 0px;">
    <div class="logo-normal">
        <a [routerLink]="['/']" class="simple-text">
            <img src="/assets/img/logo.svg" />
        </a>
    </div>
</div>
<div class="sidebar-wrapper">
    <div class="nav-container">
        <ul class="nav">
            <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
                <!--If is a single link-->
                <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
                    <i class="material-icons">{{menuitem.icontype}}</i>
                    <p>{{menuitem.title}}</p>
                </a>
                <!--If it have a submenu-->
                <a data-toggle="collapse" href="#{{menuitem.title}}" *ngIf="menuitem.type === 'sub'">
                    <i class="material-icons">{{menuitem.icontype}}</i>
                    <p>{{menuitem.title}}<b class="caret"></b></p>
                </a>

                <!--Display the submenu items-->
                <div id="{{menuitem.title}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                    <ul class="nav">
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" *ngFor="let childitem of menuitem.children">
                            <a (click)="navigate(menuitem.path, childitem.path)">
                                <span class="sidebar-normal">{{childitem.title}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>

</div>
